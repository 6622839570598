import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';
import sketch from 'vue-color/src/components/Sketch.vue';

export default {
    props: ['popupType', 'selectedItem'],
    components: {
        'sketch-picker': sketch,
    },
    data() {
        return {

            typeWarehouseList:[
                {
                    title:'Не вказано',
                    id: 'default'
                },
                {
                    title:'- зі складу',
                    id: 'minus'
                },
                {
                    title:'+ на склад',
                    id: 'plus'
                },
            ],
            typeReserveList: [
                {
                    title:'Без резерву',
                    id: 0

                },
                {
                    title:'Резерв',
                    id: 1
                }
            ],
            form: {
                title: '',
                key: '',
                color: '',
                sort: 0,
                reservable: 0,
                type: 'default'
            },
            colors: '#194d33e6',
            presetColors:[
                '#FFFFFF',
                '#EDEDED',
                '#D7D7D7',
                '#BABABA',
                '#858585',
                '#4D4D4D',
                '#262626',
                '#000000',

                '#FFEFEF',
                '#FFD1D1',
                '#FFACAC',
                '#FF9191',
                '#FF5858',
                '#FF1C1C',
                '#E50000',
                '#AE0000',

                '#FFFDE1',
                '#FEFCB4',
                '#FAFF90',
                '#F9FF79',
                '#FCFE52',
                '#FCF830',
                '#D9ED00',
                '#C8BC00',

                '#E7FFE5',
                '#BAFFB6',
                '#9EFF90',
                '#5BFF63',
                '#3AFF2D',
                '#06FF00',
                '#05E100',
                '#008E02',

                '#E1F6FF',
                '#B8EAFF',
                '#93E3FF',
                '#76E0FF',
                '#4FE4FF',
                '#01BCFF',
                '#009BD2',
                '#005EE1',

                '#FFE0FC',
                '#FFC4FA',
                '#FFADFE',
                '#FF96FA',
                '#FF83F9',
                '#FF6DF3',
                '#FF2BED',
                '#DA00C8',
            ],
            colorInput: false,
        }
    },


    validations: {
        form: {
            title: {
                required
            },
            key: {
                required
            },
            color: {
                required
            },
            sort: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            orderStatus:'orders/orderStatus',
        }),
    },
    watch:{
        orderStatus(e){
            if(e){
                this.form.title = e.title;
                this.form.color = e.color;
                this.form.sort = e.sort;
                this.form.type = e.type;
                this.form.reservable = e.reservable;
            }
        }
    }, 
    methods: { 
        closePopup() {
            this.$emit('closePopup')
        },
        sendChanges() {
            this.form.color = this.form.color.length ? this.form.color : '#ffffff';
            if(this.orderStatus){
                const payload = {
                    id: this.orderStatus.id,
                    data:this.form
                }
                this.$emit('changeOrderStatus', payload)
            }else{
                this.$emit('sendOrderStatus', this.form)
            }
        },
        openColorInput(){
            this.colors = this.form.color;
            this.colorInput = true
        },
        saveColorInput(){
            this.form.color = this.colors.hex;
            this.colorInput = false
        },
        ...mapMutations({
            changeOrderStatus:'orders/changeOrderStatus'
        })
    },
    destroyed() {
        this.changeOrderStatus(null);
    }
}